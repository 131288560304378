@mixin one-button {
	@include flex();
	justify-content: center;
	flex: 0 0 auto;
	margin: 0;
	cursor: pointer;
	border: 1px solid transparent;
	padding-top: rem(5px);
	padding-bottom: rem(5px);
	padding-left: rem(10px);
	padding-right: rem(10px);
	height: $buttonClickAreaSize;
	border-radius: $borderRadius;
	color: currentColor;
	background-color: $otherButtonBg;
	font-size: 1em;
	--rippleColor: #{#{$otherButtonHover}};

	&[disabled],
	&:disabled,
	&.one-button--disabled {
		cursor: not-allowed !important;
		color: $otherButtonDisabledText !important;
		background-color: $otherButtonDisabledBg;
		opacity: 1;
	}

	&:hover {
		transition: 0.125s;
	}

	.p-button-icon:not(.nothing) + .p-button-label {
		margin-left: .25rem;
	}
}

@mixin one-button--other {
	&:hover {
		border-color: $otherButtonHover;
	}
}


@mixin one-button--icon-only {
	height: $buttonClickAreaSize;
	width: $buttonClickAreaSize;
	padding: 0;

	&:hover {
		border-color: $otherButtonHover;
	}

	.p-button-label {
		display: none;
	}
}

@mixin one-button--primary {
	background-color: $primaryButtonBg;
	color: $primaryButtonText;
	--rippleColor: #{$primaryButtonText};

	&[disabled],
	&:disabled,
	&.one-button--disabled {
		color: $primaryButtonDisabledText;
		background-color: $primaryButtonDisabledBg;
	}

	&:hover {
		background-color: $primaryButtonHover;
	}

	.p-button-label {
		text-transform: uppercase;
		letter-spacing: normal;
	}
}

@mixin one-button--cancel {
	background-color: $cancelButtonBg;
	color: $cancelButtonText;
	--rippleColor: #{$cancelButtonText};

	&[disabled],
	&:disabled,
	&.one-button--disabled {
		color: $cancelButtonDisabledText;
		background-color: $cancelButtonDisabledBg;
	}

	&:hover {
		border-color: $cancelButtonHover;
	}

	.p-button-label {
		text-transform: uppercase;
		letter-spacing: normal;
	}
}

@mixin one-button--cancel-border {
	border-color: $cancelButtonText;
	--rippleColor: #{$cancelButtonText};

	.p-button-label {
		text-transform: uppercase;
		letter-spacing: normal;
	}
}

@mixin one-button--layout {
	background-color: transparent;
	color: $primary;
	--rippleColor: #{$primary};
	justify-content: flex-start;
	padding-left: 0;

	&[disabled],
	&:disabled,
	&.one-button--disabled {
		color: $inputValueReadOnly;
		background-color: transparent;
	}

	&:hover {
		.p-button-label {
			text-transform: initial;
			text-decoration: underline currentColor;
		}
	}
}

@mixin one-button--positive {
	background-color: $positive;
	color: $positiveContrast;
	--rippleColor: #{$positiveContrast};

	&[disabled],
	&:disabled,
	&.one-button--disabled {
		color: $primaryButtonDisabledText;
		background-color: $primaryButtonDisabledBg;
	}

	&:hover {
		border-color: $positive;
	}

	.p-button-label {
		text-transform: uppercase;
		letter-spacing: normal;
	}
}

@mixin one-button--negative {
	background-color: $negative;
	color: $negativeContrast;
	--rippleColor: #{$negativeContrast};

	&[disabled],
	&:disabled,
	&.one-button--disabled {
		color: $primaryButtonDisabledText;
		background-color: $primaryButtonDisabledBg;
	}

	&:hover {
		border-color: $negative;
	}

	.p-button-label {
		text-transform: uppercase;
		letter-spacing: normal;
	}
}

@mixin one-button--icon-right {
	@include flex();

	.p-button-label {
		order: -1;
	}
	.p-button-icon {
		order: 99;
	}
}

@mixin one-button--fab {
	--rippleColor: #{$fabText};
	background-color: $fabBg;
	color: $fabText;
	border-radius: 100vmax;
	height: $buttonClickAreaSize;
	width: $buttonClickAreaSize;
	padding: 0;

	&:hover {
		background-color: $fabBgHover;
	}

	.p-button-label {
		display: none;
	}
}

@mixin one-button--fab-item {
	--rippleColor: #{$fabItemText};

	&:hover {
		border-color: transparent;
	}
}

@mixin one-button--tray {
	color: $trayText;
	height: $trayHeight;
	width: $trayHeight;
	--rippleColor: #{$trayText};

	&[disabled],
	&:disabled,
	&.one-button--disabled {
		color: $trayButtonDisabledText;
		background-color: $trayButtonDisabledBg;
	}

	&:hover {
		color: $primary;
	}

	.p-button-label {
		font-size: .8rem;
	}
}

@mixin one-button--inline {
	justify-content: center;
	height: 100%;
	aspect-ratio: 1;

	.p-button-label {
		display: none;
	}
}

@mixin one-button--tab {
	--rippleColor: #{$otherButtonHover};
	border-width: 2px;
	border-style: solid;
	border-color: transparent;
	border-radius: 0 !important;

	&.active,
	&.p-highlight,
	&:not(:disabled).fc-button-active {
		--rippleColor: #{$primaryButtonHover};
		color: $primary;
		border-bottom-color: $primary;

		&.one-button--tab-right {
			border-bottom-color: transparent;
			border-right-color: $primary;
		}

		&.one-button--tab-left {
			border-bottom-color: transparent;
			border-left-color: $primary;
		}

		&.one-button--tab-up {
			border-bottom-color: transparent;
			border-top-color: $primary;
		}
	}

	&.one-button--tab-icon-only {
		height: $buttonClickAreaSize;
		width: $buttonClickAreaSize;
		padding: 0;

		.p-button-label {
			display: none;
		}
	}

	&:hover {
		transition: 0.25s;
		border-bottom-color: $otherButtonText;

		&.one-button--tab-right {
			border-bottom-color: transparent;
			border-right-color: $otherButtonText;
		}

		&.one-button--tab-left {
			border-bottom-color: transparent;
			border-left-color: $otherButtonText;
		}

		&.one-button--tab-up {
			border-bottom-color: transparent;
			border-top-color: $otherButtonText;
		}
	}

	.p-button-label {
		margin-left: .25rem;

		@include phone() {
			display: none;
			margin: 0;
		}
	}
}

@mixin one-button--red {
	color: $negative;

	&:hover {
		border-color: $negative;
	}
}

@mixin one-button--green {
	color: $positive;

	&:hover {
		border-color: $positive;
	}
}

@mixin one-button--blue {
	color: $info;

	&:hover {
		border-color: $info;
	}
}

@mixin one-button--snippet {
	font-size: .8rem;

	&:hover {
		color: $primary;
		border-color: transparent;
	}
}

@mixin one-button--snippet-right {
	@include one-button--snippet();

	color: $otherButtonText;
	width: $buttonClickAreaSize;
	border: $background;
	border-radius: $borderRadius;
	background-color: $otherButtonBg;
}

@mixin one-button--snippet-left {
	@include one-button--snippet();
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	width: var(--snippetWidth);
	color: currentColor;
	background-color: inherit;
}

.one-snippets {
	position: absolute;
	top: 0;
	bottom: 0;
	@include flex();
	flex-direction: column;
	justify-content: center;
	color: $otherButtonText;
	z-index: 3;
	width: var(--snippetWidth);
	height: var(--app-height);
	--snippetWidth: 1rem;

	&.one-snippets--right {
		right: 0;
		width: $barSize;
	}

	&.one-snippets--left {
		left: calc(var(--snippetWidth) * -1);
	}
}

.one-fab {
	@include flex();
	flex-direction: column;
	margin-top: auto;
	margin-right: $condensableSpacingSmall;
	margin-bottom: $condensableSpacing;
	margin-left: $elementSpacingSmall;

	.one-fab__button-items {
		position: absolute;
		bottom: calc(#{$condensableSpacing} + #{$fabSize});
		display: flex;
		flex-direction: column;
		opacity: 0;

		.one-fab__button-item {
			margin-bottom: $elementSpacingSmall;

			&:hover {
				background-color: $fabItemBgHover;
			}
		}
	}

	.one-fab__button {
		@include flex();
		justify-content: center;
		font-size: 1.4rem;
		color: $fabText;
		line-height: 100%;
		width: $fabSize;
		height: $fabSize;
		padding: 0;
		cursor: pointer;
		transition: background 0.2s ease-out;
		text-align: center;
		vertical-align: middle;
		text-decoration: none;
		border-radius: 50%;
		background-color: $fabBg;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
		-webkit-tap-highlight-color: transparent;

		&:hover {
			background-color: $fabBgHover;
		}

		&[disabled],
		&:disabled,
		&.one-button--disabled {
			color: $otherButtonDisabledText;
			background-color: white;
		}
	}

	.one-fab__button-item {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		color: $fabItemText;
		width: $fabItemSize;
		height: $fabItemSize;
		border-radius: 50%;
		background-color: $fabItemBg;

		i {
			font-size: 1rem;
		}

		&[disabled],
		&:disabled,
		&.one-button--disabled {
			color: $otherButtonDisabledText;
			background-color: white;
		}
	}
}
