@mixin flex() {
	display: flex;
	align-items: center;
}

@mixin flexChild($type) {
	flex: $type;
	min-width: 0;
	min-height: 0;
}

@mixin fullHeightSection($selector) {
	#{$selector} {
		@include flexChild(1 0);
		display: flex;
		flex-direction: column;
		overflow: hidden;
		height: 100%;
		border-radius: $borderRadius;

		&#{$selector}--padding {
			padding: $condensableSpacingSmall $condensableSpacing 0 $condensableSpacing;

			@include phone() {
				padding: $condensableSpacingSmall $condensableSpacingSmall 0 $condensableSpacingSmall;
			}
		}

		&#{$selector}--margin {
			margin: $condensableSpacing 0;
		}

		&#{$selector}--standard {
			border: thin solid $sectionBorder;
			background: none 0 0 repeat scroll $sectionBg;
		}

		.ui-datatable {
			height: 100%;
		}
	}
}

@mixin oneHorizontalStructureStyle() {
	/* used in: .one-header */
	@include flex();
	background: $headerBg;
	flex: 0 0;
	flex-basis: auto;
	justify-content: center;
	border-bottom: thin solid $border;
	padding-top: $condensableSpacingSmall;
	padding-right: $condensableSpacing;
	padding-bottom: $condensableSpacingSmall;
	padding-left: $condensableSpacing;
	z-index: 2;

	@include tablet() {
		padding: $condensableSpacingSmall;
	}
}