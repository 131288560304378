@import "print";
@import "normalize";
@import "primeflex";
@import "primengResets";
@import "typography";
@import "input";
@import "avatar";
@import "helpers";
@import "section";
@import "radio";
@import "checkbox";
@import "tooltip";
@import "dropdown";
@import "picklist";
@import "messages";
@import "dialog";
@import "animation";
@import "scrollbar";
@import "multiselect";
@import "pill";
@import "badge";
@import "tabmenu";
@import "selectButton";
@import "inputSwitch";
@import "datatable";
@import "paginator";
@import "convertPrimeiconsToFontawesome";
@import "icon";

// now add global styles here after

img.one-step-img {
	margin-top: 8%;
	margin-bottom: 3%;
}
