
.p-inline-message {
	@include flex();
	justify-content: flex-start;
	left: 0;
	font-size: $inputLabelSize;
	line-height: $inputLabelSize;
	z-index: 1;
	overflow: hidden;
	min-height: 1rem;
	text-overflow: ellipsis;

	&.p-inline-message-info {
		color: $primary;
	}

	&.p-inline-message-warn {
		color: $warning;
	}

	&.p-inline-message-error,
	&.p-inline-message-fatal {
		color: $error;
	}

	.p-inline-message-icon {
		margin-right: .25em;
		height: 0.8em;
	}
}
