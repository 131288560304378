@import "base";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "node_modules/@fortawesome/fontawesome-free/scss/mixins";
@import "node_modules/@fortawesome/fontawesome-free/scss/core";
@import "node_modules/@fortawesome/fontawesome-free/scss/icons";

.pi {
	@include fontawesome();

	&.pi-fw {
		width: $fa-fw-width;
		text-align: center;
	}

	&.pi-check::before {
		content: fa-content($fa-var-check) !important;
	}

	&.pi-minus::before {
		content: fa-content($fa-var-minus) !important;
	}

	&.pi-times::before {
		content: fa-content($fa-var-times) !important;
	}

	&.pi-times-circle::before {
		content: fa-content($fa-var-times-circle) !important;
	}

	&.pi-sort-alt {
		@include fa-icon-rotate(-90deg, 1);

		&::before {
			content: fa-content($fa-var-exchange-alt) !important;
		}
	}

	&.pi-sort-amount-up::before {
		content: fa-content($fa-var-sort-amount-up) !important;
	}

	&.pi-sort-amount-up-alt::before {
		content: fa-content($fa-var-sort-amount-up) !important;
	}

	&.pi-sort-amount-down::before {
		content: fa-content($fa-var-sort-amount-down) !important;
	}

	&.pi-sort-amount-down-alt::before {
		content: fa-content($fa-var-sort-amount-down) !important;
	}

	&.pi-chevron-right::before {
		content: fa-content($fa-var-chevron-right) !important;
	}

	&.pi-chevron-left::before {
		content: fa-content($fa-var-chevron-left) !important;
	}

	&.pi-chevron-up::before {
		content: fa-content($fa-var-chevron-up) !important;
	}

	&.pi-angle-right::before {
		content: fa-content($fa-var-angle-right) !important;; // used in paginator next-page
	}

	&.pi-angle-left::before {
		content: fa-content($fa-var-angle-left) !important;; // used in paginator prev-page
	}

	&.pi-angle-double-right::before {
		content: fa-content($fa-var-angle-double-right) !important;
	}

	&.pi-angle-double-left::before {
		content: fa-content($fa-var-angle-double-left) !important;
	}

	&.pi-angle-double-down::before {
		content: fa-content($fa-var-angle-double-down) !important;
	}

	&.pi-angle-double-up::before {
		content: fa-content($fa-var-angle-double-up) !important;
	}

	&.pi-search::before {
		content: fa-content($fa-var-search) !important;
	}

	&.pi-info-circle::before {
		content: fa-content($fa-var-info-circle) !important;
	}

	&.pi-exclamation-triangle::before {
		content: fa-content($fa-var-exclamation-triangle) !important;
	}

	&.pi-eye::before {
		content: fa-content($fa-var-eye);
	}

	&.pi-eye-slash::before {
		content: fa-content($fa-var-eye-slash);
	}

	&.pi-bars::before {
		content: fa-content($fa-var-bars);
	}

	&.pi-th-large::before {
		content: fa-content($fa-var-th-large);
	}

}

timesicon,
angleupicon,
angledownicon,
filterslashicon,
filtericon {
	svg {
		display: none;
	}
}

timesicon,
angleupicon,
angledownicon,
filterslashicon,
filtericon,
.pi {
	@extend .fa;
	width: 1.25em;
	text-align: center;
}

filtericon,
.pi-filter {
	@extend .fa-filter;
}

timesicon,
filterslashicon,
.pi-filter-slash {
	@extend .fa-times;
}

.pi-plus {
	@extend .fa-plus;
}

.pi-chevron-down {
	@extend .fa-chevron-down;
}

angledownicon,
.pi-angle-down {
	@extend .fa-angle-down;
}

angleupicon,
.pi-angle-up {
	@extend .fa-angle-up;
}

.pi {
	&.pi-calendar {
		@extend .far;
		@extend .fa-calendar-alt;
	}
}

/* and also some old font-awesome icons */

.fa {
	&.fa-trash-o::before {
		content: fa-content($fa-var-trash) !important;
	}
}