p-picklist {
	display: block;
	max-width: 100%;
}

.p-picklist {
	overflow: hidden;
	margin: $condensableSpacing 0;

	&.one-picklist--layout {
		margin: $elementSpacingSmall 0;
	}

	.p-picklist-title {
		font-size: $inputLabelSize;
		color: $inputLabel;
		line-height: 1em;
		padding: $condensableSpacingSmall $condensableSpacingSmall 0 $condensableSpacingSmall;
	}

	.p-picklist-list-wrapper {
		@include flexChild(1);
		display: flex;
		flex-direction: column;
		max-height: 50vh;
		border: thin solid $lightGray;
		border-radius: $borderRadius;
	}

	.p-picklist-filter {
		@include flex();
		border-bottom: thin solid $lightGray;
		padding: 0 $condensableSpacingSmall;

		.p-picklist-filter-input,
		.one-input--target-filter,
		.one-input--source-filter {
			width: 100%;
			line-height: $lineHeight;
		}

		.p-picklist-filter-icon {
			position: relative;
			top: unset;
			color: $otherButtonText;
			order: -1;
			margin: 0 $elementSpacingSmall 0 0;
		}

		&:focus-within {
			.p-picklist-filter-icon {
				color: $info;
			}
		}
	}

	.p-picklist-buttons {
		@include flexChild(none);
		color: $otherButtonText;
		padding: $elementSpacing;

		.p-button-icon-only {
			@include one-button();
			@include one-button--icon-only();
		}
	}
}

.p-picklist-list {
	display: var(--subGridDisplay, block);
	grid-template-columns: repeat( var(--subGridColumns, 1), auto);
	grid-auto-rows: min-content;
	height: 100%;
	overflow: auto;
	line-height: normal;
}

.p-picklist-item {
	grid-column: span var(--subGridColumns, 1);

	display: var(--subGridDisplay, block);
	grid-template-columns: subgrid;
	column-gap: $condensableSpacingSmall;
}

.one-picklist__item {
	grid-column: span var(--subGridColumns, 1);

	display: grid;
	grid-template-columns: subgrid;

	align-items: center;
}

.p-picklist-item {
	&:not(.p-picklist-droppoint) {
		padding: $condensableSpacingSmall;
		white-space: nowrap;
		text-overflow: ellipsis;
		border-radius: $borderRadius;
	}

	&:not(.cdk-drag-placeholder):not(.p-highlight) {
		&:hover,
		&:focus {
			color: $primary;
		}
	}

	&.p-highlight,
	&.p-highlight span {
		color: $primaryContrast;
		background-color: $primary;
	}

	&.cdk-drag-placeholder {
		opacity: 1;
		background-color: $highlight;
		color: $darkGray;
	}

	&.cdk-drag-preview {
		box-shadow: rgba(0, 0, 0, 0.35) 0 0 30px 0;
		background: $sectionBg;
		overflow: hidden;
	}

	ui-field-translator {
		overflow: initial;
	}

	button {
		z-index: 3;
	}
}

.one-picklist--condensed {
	.p-picklist-item:not(.p-picklist-droppoint) {
		padding: 0;
	}
}

.one-content--mobile .p-picklist,
.p-picklist.one-picklist--mobile {
	flex-direction: column;
	margin: $condensableSpacingSmall 0;

	.p-picklist-list-wrapper {
		padding: $condensableSpacingSmall;
	}

	.p-picklist-buttons {
		flex-direction: row;
		padding: $condensableSpacingSmall;
	}

	.p-picklist-transfer-buttons {
		.p-button {
			transform: rotate(90deg);
		}
	}
}
