@import "base";
/* need both classes to overwrite primeng component styles */
.p-dialog-mask.p-component-overlay {
	@include flex();
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	justify-content: center;
	overflow: hidden;
	width: 100%;
	height: 100%;
	background-color: $lightbox;
	backdrop-filter: blur(50px);
	z-index: 1000;
}

.p-dialog-mask:not(.p-component-overlay) {
	z-index: 1000;

	.p-dialog {
		box-shadow: 0 0 50px 5px #00000060;
	}
}

.p-dialog {
	@include flexChild(auto);
	@include flex();
	flex-direction: column;
	width: var(--dialogWidth);
	min-width: $navigationSize;
	max-width: var(--dialogWidth);
	height: var(--dialogHeight);
	min-height: var(--dialogHeight);
	max-height: var(--dialogHeight);
	border: thin solid $dialogBorder;
	border-radius: #{$borderRadius};
	background-color: $dialogBg;
	--dialogHeight: 80%;
	--dialogWidth: 90%;

	&.p-dialog-maximized {
		position: relative;
		bottom: unset;
		left: unset;
		--dialogHeight: 100%;
		--dialogWidth: 100%;
	}

	&.p-dialog-draggable {
		.p-dialog-header {
			cursor: move;
		}
	}

	&.one-dialog--small:not(.p-dialog-maximized),
	&.p-confirm-dialog {
		--dialogHeight: 18.75rem;
		--dialogWidth: 40%;

		.p-dialog-content {
			justify-content: center;
		}

		@include phone() {
			--dialogHeight: 80%;
			--dialogWidth: 90%;
		}
	}

	&.one-dialog--medium:not(.p-dialog-maximized),
	&.one-chat:not(.p-dialog-maximized) {
		--dialogHeight: 80%;
		--dialogWidth: 20vw;

		@include phone() {
			--dialogHeight: 80%;
			--dialogWidth: 90%;
		}
	}

	&.one-chat:not(.p-dialog-maximized) {
		position: absolute;
		bottom: $condensableSpacing;
		left: $condensableSpacing;

		@include phone() {
			position: relative;
			bottom: unset;
			left: unset;
		}
	}

	&.p-dialog--padding {
		.p-dialog-content {
			padding: $condensableSpacing;

			@include phone() {
				padding: $condensableSpacingSmall;
			}
		}
	}

	.p-dialog-header {
		@include flexChild(none);
		@include flex();
		flex-direction: row;
		justify-content: space-between;
		font-size: 1.75rem;
		color: $dialogTitleText;
		width: 100%;
		padding: $condensableSpacing;
		border-bottom: thin solid $dialogBorder;
		background-color: $dialogHeaderBg;

		.p-dialog-title__icon {
			margin-right: auto;
		}

		.p-dialog-title__icon {
			margin-right: $elementSpacingSmall;
		}

		.p-dialog-header-icon {
			@include one-button();
			@include one-button--icon-only();
			font-size: 1.25rem;
			color: $dialogTitleText;
		}

		@include phone() {
			padding: $condensableSpacingSmall;
		}
	}

	.p-dialog-content {
		@include flexChild(auto);
		display: flex;
		flex-direction: column;
		overflow-x: hidden;
		overflow-y: auto;
		width: 100%;
	}

	.p-dialog-footer {
		@include flexChild(none);
		width: 100%;
		border-top: thin solid $dialogBorder;

		.p-dialog-footer__content {
			@include flex();
			flex-direction: row;
			flex-wrap: wrap-reverse;
			justify-content: space-between;
			padding: $condensableSpacing;
			background-color: $dialogHeaderBg;

			ui-button:only-child {
				margin-left: auto;
			}

			@include phone() {
				padding: $condensableSpacingSmall;
			}
		}
	}
}

