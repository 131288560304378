/*** normalize START ***/

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	letter-spacing: -0.015em;
}

::-ms-clear {
	display: none;
}

::-ms-reveal {
	display: none;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
input,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	vertical-align: baseline;
	border: 0;
	outline: none;
}

html {
	font-size: calc(#{$basefont} / 16 * 100%);
	height: -webkit-fill-available;
	min-height: -webkit-fill-available;
	max-height: -webkit-fill-available;
	overflow: hidden;

	&:hover {
		text-overflow: clip;
	}

	text-size-adjust: calc(#{$basefont} / 16 * 100%);
}

body {
	position: relative;
	font-size: 1rem;
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	color: $text;

	/* Font settings */
	@include themefont();
	/* Font variant */
	line-height: normal;
	/* Smoothing */
	overflow: hidden;
	height: var(--app-height);

	text-transform: none;
	background: $background;
	background-color: $body;
	text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
	font-variant-ligatures: none;
	text-rendering: optimizeLegibility;
}

button,
input,
textarea {
	text-decoration: none;
	border: none;
	outline: none;
}

select {
	border: none;
	background: transparent;
	appearance: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
	display: block;
}

audio,
canvas,
progress,
video {
	display: inline-block;
	max-width: 100%;
	vertical-align: baseline;
}

audio {
	&:not([controls]) {
		display: none;
		height: 0;
	}
}

img {
	max-width: 100%;
}

[hidden],
template {
	display: none;
}

.one-link,
.one-link:active,
.one-link:hover,
a,
a:active,
a:hover {
	color: $primary;
	overflow: hidden;
	cursor: pointer;
	text-decoration: none;
	text-overflow: ellipsis;
	outline: 0;
}

.one-link:hover,
a:hover {
	text-decoration: underline;
}

abbr[title] {
	border-bottom-width: 1px;
	border-bottom-style: dotted;
}

b,
strong {
	font-weight: 700;
}

dfn {
	font-style: italic;
}

mark {
	color: #000000;
	background: #FFFF00;
}

small {
	font-size: 80%;
}

sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}

svg {
	&:not(:root) {
		overflow: hidden;
	}
}

figure {
	margin: 0;
}

pre {
	display: block;
	color: $text;
	line-height: 1.42857;
	overflow: auto;
	margin: 0 0 $elementSpacingSmall;
	padding: $elementSpacingSmall;
	word-wrap: break-word;
	word-break: break-all;
	border: 1px solid $lightGray;
	border-radius: $borderRadius;
	background-color: $ultraLightGray;

	code {
		font-size: inherit;
		color: inherit;
		padding: 0;
		white-space: pre-wrap;
		border-radius: 0;
		background-color: transparent;
	}
}

code,
kbd,
pre,
samp {
	@include monofont();
	font-size: .9rem;
}

input[type="checkbox"],
input[type="radio"] {
	line-height: normal;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0;
	-webkit-appearance: none;
	appearance: none;
	-moz-appearance: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	height: auto;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
	appearance: none;
	-moz-appearance: none;
}

input[disabled],
fieldset[disabled],
textarea[disabled] {
	opacity: 1;
	color: $inputValueReadOnly;
}

legend {
	padding: 0;
	border: 0;
}

optgroup {
	font-weight: 700;
}

table {
	border-spacing: 0;
	border-collapse: collapse;
	background-color: transparent;

	th {
		@include th_style();
		@include th_text();
	}

	tbody tr {
		@include tr_style();
	}

	col[class*="col-"] {
		position: static;
		display: table-column;
		float: none;
	}
}

td,
th {
	padding: 0;
}

[role="button"] {
	cursor: pointer;
}

ol ol,
ol ul,
ul ol,
ul ul {
	margin-bottom: 0;
}

dd,
dt {
	line-height: 1.42857;
}

dt {
	font-weight: 700;
}

dd {
	margin-left: 0;
}

abbr[data-original-title],
abbr[title] {
	cursor: help;
	border-bottom: 1px dotted $darkGray;
}

blockquote {
	font-size: 1rem;
	color: $mediumGray;
	margin: 0 0 $condensableSpacing;
	padding: $condensableSpacingSmall $condensableSpacing;
	border-left: rem(5px) solid $lightGray;
}

blockquote ol:last-child,
blockquote p:last-child,
blockquote ul:last-child {
	margin-bottom: 0;
}

.blockquote-reverse,
blockquote.pull-right {
	text-align: right;
	border-right: rem(5px) solid $lightGray;
	border-left: 0;
}

.blockquote-reverse .small::before,
.blockquote-reverse footer::before,
.blockquote-reverse small::before,
blockquote.pull-right .small::before,
blockquote.pull-right footer::before,
blockquote.pull-right small::before {
	content: "";
}

address {
	font-style: normal;
	line-height: 1.42857;
	margin-bottom: 20px;
}

code {
	color: $secondary;
	border-radius: $borderRadius;
	background-color: rgba(243, 229, 233, 0.5);
}

kbd {
	font-size: 90%;
	color: #FFFFFF;
	padding: 2px 4px;
	border-radius: $borderRadius;
	background-color: $text;
	box-shadow: rgba(0, 0, 0, 0.247059) 0 -1px 0 inset;

	kbd {
		font-size: 100%;
		font-weight: 700;
		padding: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
}

caption {
	color: $darkGray;
	padding-top: 8px;
	padding-bottom: 8px;
	text-align: left;
}

select[multiple],
select[size] {
	height: auto;
}

output {
	display: block;
	font-size: rem(13px);
	color: #555555;
	line-height: 1.42857;
	padding-top: 7px;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote::after,
blockquote::before,
q::after,
q::before {
	content: "";
}

:focus {
	outline: 0;
}

form {
	margin: 0;
}

button,
input,
optgroup,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	font-weight: var(--inputWeight, inherit);
	font-style: inherit;
	font-variant: inherit;
	font-stretch: inherit;
	color: inherit;
	line-height: inherit;
	margin: 0;
	background-color: transparent;
}

button {
	border: none;
	background: none;
	appearance: none;
}

del {
	color: $mediumGray;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	opacity: 1; /* Firefox */
	color: $placeholder;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: $placeholder;
}

::-ms-input-placeholder { /* Microsoft Edge */
	color: $placeholder;
}

/*** normalize END ***/
