@keyframes shake {
	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}
	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}
	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}
	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}


@keyframes fadeIn {
	0% {
		opacity: 0;
		height: 0;
		padding: 0;
		width: 0;
	}
	100% {
		opacity: 1;
		height: auto;
		width: auto;
	}
}


@keyframes fadeOut {
	0% {
		opacity: 1;
		height: auto;
		width: auto;
	}
	100% {
		opacity: 0;
		height: 0;
		padding: 0;
		width: 0;
	}
}


@keyframes scaleIn {
	from {
		transform: scale(0.95);
	}
	to {
		transform: scale(1.0);
	}
}


@keyframes scaleOut {
	from {
		transform: scale(1.0);
	}
	to {
		transform: scale(0.95);
	}
}

/* RIPPLE */

.p-ripple {
	position: relative;
	overflow: hidden;
}

.p-ink {
	position: absolute;
	display: block;
	opacity: .5;
	transform: scale(0);
	border-radius: 100%;
	background: var(--rippleColor, var(--primary));
}

.p-ink-active {
	animation: ripple .4s linear;
}

.p-ripple-disabled .p-ink {
	display: none ! important;
}

@keyframes ripple {
	to {
		opacity: 0;
		transform: scale(2.5);
	}
}

/* oneTransitions */

.one-transition {
	transition: height 250ms ease-in-out 50ms,
	visibility 200ms ease 250ms;
}

.one-transition--hide {
	visibility: hidden;
	height: 0;
}

.one-transition--show {
	visibility: visible;
	height: calc(#{$buttonClickAreaSize} * 3);
}