@import "base";

/* tri- and bistate checkbox share styles */

p-tableHeaderCheckbox,
p-checkbox {
	display: inline-flex;
	align-items: center;
}

p-tristatecheckbox,
p-checkbox {

	&.one-checkbox--in-input-wrapper {
		order: -1;
		margin-right: $elementSpacingSmall;
	}
}

p-tristatecheckbox,
.p-checkbox {
	@include flex();
	@include flexChild(none);
	height: $lineHeight;
}

.p-checkbox-box {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: .75rem;
	color: $inputLabel;
	line-height: $lineHeight;
	width: 1.3rem;
	height: 1.3rem;
	border: thin solid $inputLabel;
	border-radius: $borderRadius;
	speak: none;

	& > * {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	&:hover {
		color: $primary;
		border-color: $primary;
	}

	&.p-disabled {
		cursor: not-allowed;
		color: $inputValueReadOnly;
		border-color: $inputValueReadOnly;
		background-color: $lightGray;

		&:hover {
			color: $inputValueReadOnly;
			border-color: $inputValueReadOnly;
		}
	}
}

.p-checkbox-label {
	color: $inputLabel;
	margin-left: $elementSpacingSmall;
	cursor: pointer;

	&.p-disabled {
		color: $inputValueReadOnly;
		cursor: not-allowed;
	}
}

/* overwrite svg icons */

.p-checkbox-box {
	svg {
		display: none
	}
}

.p-checkbox {
	.p-checkbox-box {
		@include fontawesome();

		& > checkicon::before {
			content: '\f00c'; //fa-check;
		}
	}
}

p-tristatecheckbox {
	.p-checkbox-box {

		&:empty::before {
			content: '\f128'; //fa-question;
		}
	}
}