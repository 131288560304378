.one-section {
	position: relative;
	border-radius: $borderRadius;

	&.one-section--standard {
		padding: $condensableSpacingSmall $condensableSpacing;

		@include phone() {
			padding: $condensableSpacingSmall $condensableSpacingSmall;
		}
	}

	&.one-section--toggleable {

		.p-datatable-wrapper--related-list .one-section__header,
		& > .one-section__header {
			cursor: pointer;

			&:hover {
				color: $primary;
			}
		}
	}

	&.one-section--background {
		background: $sectionBg;
	}

	&.one-section--border {
		border: thin solid $sectionBorder;
	}

	&.one-section--no-title {
		padding-top: $condensableSpacing;
	}

	&.one-section--full-height {
		display: flex;
		flex-direction: column;
		height: 100%;

		.one-section__header {
			flex: none;
			width: 100%;
		}

		.one-section__content {
			flex: auto;
		}
	}

	&:before {
		position: absolute;
		content: "";
		filter: blur(10px);
	}

	& > .one-section__header {
		position: relative;
	}

	.one-section__header {
		@include flexChild(auto);
		display: flex;
		align-items: flex-start;
		align-self: flex-start;
		color: $sectionTitleText;
		line-height: $buttonClickAreaSize;
		word-break: break-word;
	}

	.one-section__title {
		@include flex();
		@include flexChild(auto);
		line-height: $buttonClickAreaSize;
		word-wrap: break-word;
		color: $sectionTitleText;

		&.one-section__title--no-wrap span {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	.one-section__tools {
		@include flex();
		@include flexChild(none);
		margin-left: auto;

		.one-button {
			color: $otherButtonText;
		}
	}

	.one-section__icon {
		@include flexChild(0 0 auto);
		@include flex();
		align-self: flex-start;
		justify-content: center;
		font-size: .7em;
		color: $sectionIcon;
		width: auto;
		height: $buttonClickAreaSize;
		pointer-events: none;

		&.one-section__icon--margin {
			margin-right: $elementSpacingSmall;
		}
	}
}

.one-section__toggle {
	@include one-button();
	@include one-button--icon-only();
	color: inherit;
	line-height: $buttonClickAreaSize;
	transition: transform 250ms ease-in-out;

	&--right {
		transform: rotate(-90deg);
	}
}
