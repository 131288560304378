.p-overlay-badge {
	position: relative;
}

.p-badge {
	@include flex();
	justify-content: center;
	font-size: .7em;
	color: $positiveContrast;
	height: 1.2em;
	padding-inline: 0.4em;
	border-radius: 100vmax;
	background-color: $positive;
}
