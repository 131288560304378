// fonts:
@mixin themefont {
	font-family: #{$basefontFamily}, sans-serif;
}

@mixin monofont($imp: "") {
	font-family: ui-monospace, "Cascadia Mono", "Segoe UI Mono", "Liberation Mono", Menlo, Monaco, Consolas, monospace unquote($imp);
}

@mixin fontawesome {
	font-family: "Font Awesome 5 Free" !important;
	font-weight: 900 !important;
}

@mixin fontawesomeRegular {
	font-family: "Font Awesome 5 Free" !important;
	font-weight: 400 !important;
}

/*** Typography ***/

@mixin typo-all {
	@include flex();
	margin: 0;
	padding: 0;
}

@mixin typo-h1 {
	font-size: $fontH1Size;
	font-weight: $fontH1Weight;
	letter-spacing: normal;
	color: currentColor;

	@include phone() {
		font-size: $fontH1SizePhone;
	}
}

@mixin typo-h2 {
	font-size: $fontH2Size;
	font-weight: $fontH2Weight;
	letter-spacing: normal;
	color: currentColor;

	@include phone() {
		font-size: $fontH2SizePhone;
	}
}

@mixin typo-h3 {
	font-size: $fontH3Size;
	font-weight: $fontH3Weight;
	letter-spacing: normal;
	color: currentColor;

	@include phone() {
		font-size: $fontH3SizePhone;
	}
}

@mixin typo-h4 {
	font-size: $fontH4Size;
	font-weight: $fontH4Weight;
	letter-spacing: normal;

	@include phone() {
		font-size: $fontH4SizePhone;
	}
}

@mixin typo-h5 {
	font-size: $fontH5Size;
	font-weight: $fontH5Weight;
	letter-spacing: normal;

	@include phone() {
		font-size: $fontH5SizePhone;
	}
}

@mixin typo-h6 {
	font-size: $fontH6Size;
	font-weight: $fontH6Weight;
	letter-spacing: normal;

	@include phone() {
		font-size: $fontH6SizePhone;
	}
}

@mixin typo-small {
	font-size: $fontSmallSize;
}