@import "base";

.one-avatar {
	@include flexChild(none);
	@include flex();
	flex-basis: var(--oneAvatarDimension, #{$navigationAvatarSize});
	justify-content: center;
	color: currentColor;
	overflow: hidden;
	width: var(--oneAvatarDimension, #{$navigationAvatarSize});
	height: var(--oneAvatarDimension, #{$navigationAvatarSize});
	border-radius: 50%;
	aspect-ratio: 1;

	&.one-avatar--margin {
		margin-right: $inlineGapColumn;
	}

	&.one-avatar--border {
		border: thin solid $border;
	}

	&.one-avatar--big {
		font-size: 1.5rem;
	}

	&.one-avatar--original {
		flex-basis: auto;
		width: rem(300px);
		height: rem(300px);
		box-shadow: $shadowAll;
	}

	&.one-avatar--empty {
		color: var(--oneAvatarColorFG);
		border: thin solid var(--oneAvatarColorFG);
		background-color: var(--oneAvatarColorBG);
	}

	.one-initials {
		font-size: .9em;
	}

	img {
		position: inherit;
		color: $navigationText;
		line-height: $lineHeight;
		width: 100%;
		height: 100%;
		letter-spacing: -2px;
		background-repeat: no-repeat;
		-o-object-fit: cover;
		object-fit: cover;
	}
}

.one-avatar--live-avatars {
	display: flex;
	flex-direction: row;
}

.one-avatar--status {
	position: absolute;
	top: 50%;
	left: 35%;
}

.one-avatar--active {
	color: $online;
}

.one-avatar--away {
	color: $offline;
}

.one-avatar--inactive {
	color: $away;
}

.one-avatar--action {
	font-size: 8px;
	color: white;
}

