// responsive:
$desktop: 1200px;
$tablet: 960px; // align with primeNg breakpoint
$phone: 600px;

// Small devices
@mixin phone {
	@media (max-width: #{$phone}) {
		@content;
	}
}

// Small devices
@mixin minPhone {
	@media (min-width: #{$phone}) {
		@content;
	}
}

// Medium devices
@mixin tablet {
	@media (max-width: #{$tablet}) {
		@content;
	}
}

// Medium devices
@mixin minTablet {
	@media (min-width: #{$tablet}) {
		@content;
	}
}

// Large devices
@mixin desktop {
	@media (max-width: #{$desktop}) {
		@content;
	}
}

// Large devices
@mixin minDesktop {
	@media (min-width: #{$desktop}) {
		@content;
	}
}