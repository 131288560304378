/*** Typography ***/

h1, h2, h3, h4, h5, h6 {
	@include typo-all();
}

h1 {
	@include typo-h1();

	& * {
		font-size: inherit;
		font-weight: inherit;
		letter-spacing: inherit;
		color: inherit;
	}
}

h2 {
	@include typo-h2();

	& * {
		font-size: inherit;
		font-weight: inherit;
		letter-spacing: inherit;
		color: inherit;
	}
}

h3 {
	@include typo-h3();

	& * {
		font-size: inherit;
		font-weight: inherit;
		letter-spacing: inherit;
		color: inherit;
	}
}

h4 {
	@include typo-h4();

	& * {
		font-size: inherit;
		font-weight: inherit;
		letter-spacing: inherit;
		color: inherit;
	}
}

h5 {
	@include typo-h5();

	& * {
		font-size: inherit;
		font-weight: inherit;
		letter-spacing: inherit;
		color: inherit;
	}
}

h6 {
	@include typo-h6();

	& * {
		font-size: inherit;
		font-weight: inherit;
		letter-spacing: inherit;
		color: inherit;
	}
}

small {
	@include typo-small();
}