/*** WSP Colors ***/
@import "functions";


// images:
$companyLogo: var(--companyLogo);
$companyLogoSize: var(--companyLogoSize);
$leftmenuLogo: var(--leftmenuLogo);
$leftmenuLogoPosition: var(--leftmenuLogoPosition);
$leftmenuLogoSize: var(--leftmenuLogoSize);
$footerLogo: var(--footerLogo);
$loginBg: var(--loginBg);
// typography:
$basefontFamily: var(--basefontFamily);
$basefont: var(--basefont);
$fontH1Size: var(--fontH1Size, 1.802rem);
$fontH1SizePhone: var(--fontH1SizePhone, 1.602rem);
$fontH1Weight: var(--fontH1Weight, 600);
$fontH2Size: var(--fontH2Size, var(--fontH1SizePhone));
$fontH2SizePhone: var(--fontH2SizePhone, 1.424rem);
$fontH2Weight: var(--fontH2Weight, 600);
$fontH3Size: var(--fontH3Size, var(--fontH2SizePhone));
$fontH3SizePhone: var(--fontH3SizePhone, 1.266rem);
$fontH3Weight: var(--fontH3Weight, 600);
$fontH4Size: var(--fontH4Size, var(--fontH3SizePhone));
$fontH4SizePhone: var(--fontH4SizePhone, 1.125rem);
$fontH4Weight: var(--fontH4Weight, 600);
$fontH5Size: var(--fontH5Size,var(--fontH4SizePhone));
$fontH5SizePhone: var(--fontH5SizePhone, 1rem);
$fontH5Weight: var(--fontH5Weight, 600);
$fontH6Size: var(--fontH6Size, var(--fontH5SizePhone));
$fontH6SizePhone: var(--fontH6SizePhone, 1rem);
$fontH6Weight: var(--fontH6Weight, 600);
$fontSmallSize: var(--fontSmallSize, .8706rem);
// general:
$lineHeight: var(--lineHeight);
$elementSpacing: var(--elementSpacing);
$elementSpacingSmall: var(--elementSpacingSmall);
$condensableSpacing: var(--condensableSpacing);
$condensableSpacingSmall: var(--condensableSpacingSmall);
$barSize: var(--barSize);
$background: var(--background, --body);
$body: var(--body);
$text: var(--text);
$darkGray: var(--darkGray);
$mediumGray: var(--mediumGray);
$lightGray: var(--lightGray);
$ultraLightGray: var(--ultraLightGray);
$green: var(--green);
$orange: var(--orange);
$red: var(--red);
$blue: var(--blue);
$primary: var(--primary);
$primaryContrast: var(--primaryContrast);
$secondary: var(--secondary);
$secondaryContrast: var(--secondaryContrast);
$lightbox: var(--lightbox);
$highlight: var(--highlight);
$border: var(--border);
$placeholder: var(--placeholder);
// buttons:
$primaryButtonText: var(--primaryButtonText);
$primaryButtonHover: var(--primaryButtonHover);
$primaryButtonBg: var(--primaryButtonBg);
$primaryButtonDisabledText: var(--primaryButtonDisabledText);
$primaryButtonDisabledBg: var(--primaryButtonDisabledBg);
$cancelButtonText: var(--cancelButtonText);
$cancelButtonHover: var(--cancelButtonHover);
$cancelButtonBg: var(--cancelButtonBg);
$cancelButtonDisabledText: var(--cancelButtonDisabledText);
$cancelButtonDisabledBg: var(--cancelButtonDisabledBg);
$otherButtonText: var(--otherButtonText);
$otherButtonHover: var(--otherButtonHover);
$otherButtonBg: var(--otherButtonBg);
$otherButtonDisabledText: var(--otherButtonDisabledText);
$otherButtonDisabledBg: var(--otherButtonDisabledBg);
$buttonClickAreaSize: var(--buttonClickAreaSize);
$fabSize: var(--fabSize);
$fabBg: var(--fabBg);
$fabBgHover: var(--fabBgHover);
$fabText: var(--fabText);
$fabItemSize: var(--fabItemSize);
$fabItemBg: var(--fabItemBg);
$fabItemBgHover: var(--fabItemBgHover);
$fabItemText: var(--fabItemText);
// navigation:
$navigationSize: var(--navigationSize);
$navigationBg: var(--navigationBg);
$navigationText: var(--navigationText);
$navigationActiveText: var(--navigationActiveText);
$navigationActiveBg: var(--navigationActiveBg);
$navigationHoverBg: var(--navigationHoverBg);
$navigationHoverText: var(--navigationHoverText);
$navigationBorder: var(--navigationBorder);
$navigationItemHeight: var(--buttonClickAreaSize);
$navigationAvatarSize: var(--navigationAvatarSize);
$navigationScrollBarBg: var(--navigationScrollBarBg);
$navigationScrollHandleBg: var(--navigationScrollHandleBg);
$navigationScrollHandleBgHover: var(--navigationScrollHandleBgHover);
// tray
$trayBg: var(--trayBg);
$trayText: var(--trayText);
$trayHeight: var(--trayHeight);
$trayButtonDisabledBg: var(--trayButtonDisabledBg);
$trayButtonDisabledText: var(--trayButtonDisabledText);
//scrollbars
$scrollBarBg: var(--scrollBarBg);
$scrollBarWidth: var(--scrollBarWidth);
$scrollBarHandleBg: var(--scrollBarHandleBg);
$scrollBarHandleBgHover: var(--scrollBarHandleBgHover);
$scrollBarRadius: var(--scrollBarRadius);
$scrollBarBorderWidth: var(--scrollBarBorderWidth);
// header:
$headerBg: var(--headerBg);
$searchCategory: var(--searchCategory);
$searchCategoryContrast: var(--searchCategoryContrast);
$searchButton: var(--searchButton);
$searchButtonContrast: var(--searchButtonContrast);
$searchBorder: var(--searchBorder);
$searchBg: var(--searchBg);
$searchTooltip: var(--mediumGray);
// dialogs:
$dialogBg: var(--dialogBg);
$dialogBorder: var(--dialogBorder);
$dialogHeaderBg: var(--dialogHeaderBg);
$dialogTitleText: var(--dialogTitleText);
// dropdowns:
$dropdownBg: var(--dropdownBg);
$dropdownText: var(--dropdownText);
$dropdownGroupBg: var(--dropdownGroupBg);
$dropdownGroupBgContrast: var(--dropdownGroupBgContrast);
// sections:
$sectionBg: var(--sectionBg);
$sectionTitleText: var(--sectionTitleText);
$sectionIcon: var(--sectionIcon);
$sectionBorder: var(--sectionBorder);
// activity stream:
$streamHeaderText: var(--streamHeaderText);
$streamLabel: var(--streamLabel);
$streamLabelOld: var(--streamLabelOld);
$streamValueToIcon: var(--streamValueToIcon);
$streamAvatarSize: var(--streamAvatarSize);
// infoView:
$infoViewBg: var(--infoViewBg);
// inputs:
$inputLabel: var(--inputLabel);
$inputLabelSize: var(--inputLabelSize);
$inputValueReadOnly: var(--inputValueReadOnly);
$inputBorder: var(--inputBorder);
$inputSwitchBg: var(--inputSwitchBg);
$inputSwitchHandleBg: var(--inputSwitchHandleBg);
// lists:
$tableRowSpacing: var(--tableRowSpacing);
$tableRowText: var(--tableRowText);
$tableRowBg: var(--tableRowBg);
$tableRowOdd: var(--tableRowOdd);
$tableRowEven: var(--tableRowEven);
$tableRowHoverBg: var(--tableRowHoverBg);
$tableRowHoverText: var(--tableRowHoverText);
$tableRowBorderBottom: var(--tableRowBorderBottom);
$tableBorder: var(--tableBorder);
$tableAvatarSize: var(--tableAvatarSize);
$tableFilterBg: var(--tableFilterBg);
$tableHeaderText: var(--tableHeaderText);
$tableHeaderBg: var(--tableHeaderBg);
$tableHeaderBgHover: var(--tableHeaderBgHover);
$tableFilterFontSize: var(--tableFilterFontSize);
// actions:
$positive: var(--positive);
$positiveContrast: var(--positiveContrast);
$negative: var(--negative);
$negativeContrast: var(--negativeContrast);
// avatar and user status:
$online: var(--online);
$away: var(--away);
$offline: var(--offline);
// growls, notifications, messages:
$growlText: var(--growlText);
$growlBg: var(--growlBg);
$info: var(--info);
$infoContrast: var(--infoContrast);
$warning: var(--warning);
$warningContrast: var(--warningContrast);
$error: var(--error);
$errorContrast: var(--errorContrast);
// tooltips:
$tooltipBg: var(--tooltipBg);
$tooltipText: var(--tooltipText);
// steps:
$stepItem: var(--stepItem);
$stepItemCurrent: var(--stepItemCurrent, --stepItem);
$stepItemCurrentContrast: var(--stepItemCurrentContrast, --stepItem);
$stepBorder: var(--stepBorder);
$stepBorderCurrent: var(--stepBorderCurrent, --stepBorder);
$stepBg: var(--stepBg);
//progressbar
$progressBarBg: var(--progressBarBg);
$progressBarHeight: var(--progressBarHeight);
$progressBarRadius: var(--progressBarRadius);
// shadows
$shadowDown: var(--shadowDown);
$shadowUp: var(--shadowUp);
$shadowRight: var(--shadowRight);
$shadowLeft: var(--shadowLeft);
$shadowAll: var(--shadowAll);
// corners
$borderRadius: var(--borderRadius);
// LIVE features:
$liveAvatarSize: var(--liveAvatarSize);
// footer:
$footerText: var(--footerText);
$sectionRowHeight: var(--sectionRowHeight);
// flow editor
$flowBg: var(--flowBg);
$flowElementBg: var(--flowElementBg);
$flowConnectorHeight: var(--flowConnectorHeight);
$flowConnectorColor: var(--flowConnectorColor);
// inline styles
$inlineIconSize: var(--inlineIconSize, .75rem);
$inlineGapColumn: var(--inlineGapColumn, .25rem);
$inlineGapRow: var(--inlineGapRow, .125rem);
$inlineTagHeight: var(--inlineTagHeight, 1.5rem);