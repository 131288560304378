.ui-helper-hidden,
.ui-helper-hidden-accessible,
.p-hidden-space,
.p-hidden {
	display: none;
}

.p-hidden-accessible {
	position: absolute;
	overflow: hidden;
	clip: rect(0 0 0 0);
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
}

.disabled {
	pointer-events: none;
}

.one-helper--highlight {
	border-radius: $borderRadius;
	box-shadow: 0 0 0 3px $green;
}

.one-helper-hidden--phone {
	@include phone() {
		display: none !important;
		margin: 0 !important;
	}
}

.one-helper-hidden--tablet {
	@include tablet() {
		display: none !important;
		margin: 0 !important;
	}
}

.one-helper-hidden--desktop {
	@include desktop() {
		display: none !important;
		margin: 0 !important;
	}
}

.no-select {
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-webkit-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
}

.one-helper--pull-right {
	margin-left: auto;
}

.one-helper--center-50 {
	width: 50vw;
	min-height: 50vh;
	margin: auto auto;
}

.one-helper--flexwrap {
	flex-wrap: wrap;
}

.one-helper--pointer {
	cursor: pointer;
}

.one-helper--quarantined {
	color: $negative;
	font-weight: bold;
}

.p-scroller-content {
	min-height: auto;
}