@mixin tokenVars() {
	--tokenGapColumn: #{$inlineGapColumn};
	--tokenGapRow: #{$inlineGapRow};
	--tokenOuterPadding: #{rem(7.5px)};
	--tokenOuterPaddingRounded: #{rem(10px)};
	--tokenHeight: #{$inlineTagHeight};
	--pillHeight: var(--tokenHeight, 1.5rem); // use height within multi-AC or own
	--navigationAvatarSize: var(--pillHeight);
}

@mixin tokenBasics() {
	@include tokenVars();
	display: inline-flex;
	align-items: center;
	max-width: 100%;
	cursor: default;
	background-color: $lightGray;
	line-height: var(--tokenHeight);
	height: var(--tokenHeight);
	border-radius: $borderRadius;
	padding-left: var(--tokenOuterPadding);
}

.p-fileupload-multiple-container,
.p-autocomplete-multiple-container,
.p-chips-multiple-container {
	@include tokenVars();
	@include flex();
	flex-wrap: wrap;
	column-gap: var(--tokenGapColumn);
	row-gap: var(--tokenGapRow);
	margin: 0;
	padding: 0;
	list-style-type: none;
	cursor: text;
	overflow: hidden;
	width: 100%;
	font-size: $inputLabelSize;
	min-height: $lineHeight;
}

.p-fileupload-token,
.p-autocomplete-token {
	@include tokenBasics();
	@include flexChild(none);

	ui-field-translator {
		overflow: initial;

		& + ui-field-translator {
			margin-left: $inlineGapColumn;
		}

		ui-rep-image {
			height: var(--pillHeight, var(--tokenHeight));
			margin-left: calc(var(--tokenOuterPadding) * -1);
			font-size: 0.65rem;

			&::before {
				content: '';
				position: absolute;
				background: white;
				height: 100%;
				width: 50%;
				left: 0;
				z-index: 0;
			}

			.one-avatar {
				z-index: 1;
				border: thin solid $lightGray;
			}
		}
	}
}

.p-fileupload-token--new {
	padding: 0 var(--tokenOuterPadding);
}

.p-autocomplete-token-label,
.p-chips-token-label,
.one-pill__label {
	@include flex();
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.p-fileupload-token-icon,
.p-chips-token-icon,
.p-autocomplete-token-icon {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: var(--tokenHeight);
	height: var(--tokenHeight);
	aspect-ratio: 1;
	cursor: pointer;

	&:active,
	&:hover {
		color: $negative;
	}
}

.p-chips-input-token,
.p-autocomplete-input-token {
	@include flexChild(auto);

	input {
		line-height: var(--tokenHeight);
		height: var(--tokenHeight);
		width: 100%;
		font-size: 1rem;
	}
}

.p-chips {
	width: 100%;
}

one-pill {
	@include tokenVars();
	display: inline-flex;
	align-items: center;
	flex-wrap: wrap;

	& > * {
		@include flexChild(none);
	}
}

one-pill {

	& + one-pill {
		margin-left: var(--tokenGapColumn);
	}
}

.p-chips-token {
	@include tokenBasics();
}

.one-pill {
	@include tokenBasics();
	padding-right: var(--tokenOuterPadding);
}

.one-pill--rounded {
	border-radius: 100vmax;
	--tokenOuterPadding: var(--tokenOuterPaddingRounded);
}

.one-pill__icon {
	font-size: $inlineIconSize;
	margin-right: 0.2em;
}