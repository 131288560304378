@import "_base";

.one-background {
	position: absolute;
	top: 0;
	z-index: 1;
	width: 100%;
	height: 100vh;
	background-color: $sectionBg;

	@include minPhone() {
		background-image: $loginBg;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
}

.one-fullscreen {
	@include flex();
	flex-direction: column;
	justify-content: center;
	z-index: 3;
	width: 100%;
	height: 100vh;
}

.one-fullscreen__wrapper {
	@include flexChild(none);
	@include flex();
	z-index: 3;
	margin: auto;
}

.one-login {
	@include flex();
	flex-direction: column;
	justify-content: center;
	z-index: 3;
	width: 100vw;
	max-height: 90vh;
	margin: auto auto;
	padding: $condensableSpacing;
	background-color: $sectionBg;

	@include minPhone() {
		width: 30rem;
		padding: rem(40px);
		border-radius: calc(#{$borderRadius} * 3);
		box-shadow: $shadowAll;
	}
}

.one-portal__footer {
	@include flexChild(none);
	z-index: 3;
}

.one-gradient {
	@include minPhone() {
		position: absolute;
		top: 0;
		z-index: 2;
		width: 100%;
		height: 100vh;
		background: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, .65));
	}
}
