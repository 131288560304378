@media print {

	:root {
		--sectionBg: transparent;
		--body: transparent;
		--background: white;

		-webkit-print-color-adjust: exact;
	}

	* {
		transition: none !important;
	}

	.p-toast,
	.one-snippets,
	.one-content__right,
	.one-content__left,
	.one-tray,
	.p-tooltip,
	.one-logentry__type,
	.one-page__left,
	.one-page__right,
	.one-header__tools,
	.one-section__toggle,
	.one-section__tools,
	.ui-column-customfilter,
	.one-editor__buttons,
	.one-stream__editor-modes,
	.one-stream__footer,
	.p-editor-content,
	.one-lightbox--mobile,
	.one-avatar--live-cursor,
	.one-header {
		display: none !important;
	}

	.one-logentry__header {
		visibility: hidden !important;
	}


	html,
	body,
	portal-root,
	platform-one-page,
	portal-layout-form,
	ui-main-structure,
	.one-content,
	.one-layout,
	.one-page,
	.one-page__main {
		display: block !important;
		overflow: initial !important;
		height: auto !important;
		max-height: unset !important;
	}

	.one-layout {
		display: flex !important;
		flex-direction: column-reverse !important;
	}

	.p-datatable,
	.p-datatable-thead th {
		height: auto !important;
		max-height: unset !important;
	}


	thead {
		display: table-header-group;
	}

	tfoot {
		display: table-footer-group;
	}

	.p-editor-toolbar,
	.one-editor__header {
		border: none !important;
	}


	.one-list {
		border: none !important;
	}

	.p-datatable {
		& > *:not(.p-datatable-header),
		.p-dropdown-trigger {
			display: none;
		}

		.p-datatable-header::after {
			content: 'Bitte Export-Funktion benutzen. / Please use export function.';
			text-align: center;
		}
	}

	gridster-item {
		position: relative !important;
		width: 100% !important;
		max-width: 100% !important;
		height: auto !important;
		max-height: unset !important;
		margin-bottom: $condensableSpacing;
		transform: none !important;
	}

	portal-layout-section {
		margin: 10px;
	}

	textarea {
		overflow: initial !important;
		height: auto !important;
		max-height: unset !important;
	}

	.p-selectbutton.one-selectbutton--form {
		flex-wrap: nowrap !important;
	}

	.one-record {
		display: block !important;
	}

}
