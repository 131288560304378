@import "base";

portal-checkbox {
	.p-disabled label {
		cursor: default;
	}

	label {
		cursor: pointer;
	}
}

p-inputswitch {
	display: flex;
	align-items: center;
	order: -1;
	height: $lineHeight;
	margin-right: $elementSpacingSmall;
	--switchSliderHeight: 1rem;
	--switchSpacing: .15rem;
}

.p-inputswitch {
	position: relative;
	display: inline-block;
	width: calc(var(--switchSliderHeight) * 2 + var(--switchSpacing) * 2);
	height: calc(var(--switchSliderHeight) + var(--switchSpacing) * 2);
	-ms-user-select: none;
	-webkit-user-select: none;
	user-select: none;
	--switchSliderBg: #{$inputSwitchBg};
	--switchSliderHandleBg: #{$inputSwitchHandleBg};

	&.p-inputswitch-checked {
		--switchSliderBg: #{$positive};
		--switchSliderHandleBg: #{$positiveContrast};

		.p-inputswitch-slider:before {
			font-size: 0.65rem;
			content: '\f00c'; // fa-check
			transform: translateX(var(--switchSliderHeight));
		}
	}

	&.p-disabled {
		opacity: .5;

		.p-inputswitch-slider {
			cursor: default;
		}
	}
}

.p-inputswitch-slider {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	cursor: pointer;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	border-radius: 100vmax;
	background: var(--switchSliderBg);

	&:before {
		@include flex();
		@include fontawesome();
		position: absolute;
		top: 50%;
		left: var(--switchSpacing);
		justify-content: center;
		font-size: 0.75rem;
		color: var(--switchSliderBg);
		width: var(--switchSliderHeight);
		height: var(--switchSliderHeight);
		margin-top: -0.5rem;
		content: '\f00d'; // fa-times
		transition-duration: 0.2s;
		border-radius: 100vmax;
		background: var(--switchSliderHandleBg);
	}
}

.p-inputswitch.p-error, .p-inputswitch.p-invalid {
	border-color: $error;
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
	border-color: $error;
}

.p-inputswitch:not(.p-disabled):hover,
.p-inputswitch-label:not(.p-disabled):hover + p-inputswitch .p-inputswitch {

	.p-inputswitch-slider {
		&:before {
			font-size: 0.75rem;
			content: '\f061'; // fa-arrow-right
		}
	}

	&.p-inputswitch-checked {
		.p-inputswitch-slider {
			&:before {
				content: '\f060'; // fa-arrow-left
			}
		}
	}
}