p-tabview {
	display: block;
}

.p-tabview {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	height: 100%;
}

.p-tabview-nav {
	@include flex();
	@include flexChild(none);
	flex-wrap: wrap;
	justify-content: flex-start;
	width: 100%;
	border-bottom: 2px solid $lightGray;

	li {
		@include flexChild(none);
		z-index: 1;
		max-width: #{$navigationSize};
		border: solid $lightGray;
		border-width: 0 0 2px 0;
		margin: 0 0 -2px 0; // shift item down by thinkness of border to have borders of navbar and navitem on top of each other
		transition: 0.25s;

		&:active,
		&:focus-within,
		&:hover {
			border-bottom-color: $otherButtonText;
		}

		&.p-highlight {
			z-index: 2;
			border-bottom-color: $primary;

			.p-tabview-nav-link {
			}
		}

		.p-tabview-nav-link {
			color: $otherButtonText;

			&:hover {
				text-decoration: none;
			}

			& > * {
				@include flexChild(auto);
			}
		}

		.p-tabview-left-icon {
			margin-left: $elementSpacingSmall;
		}

		.p-tabview-title {
			overflow: hidden;
			margin-left: $elementSpacingSmall;
			text-overflow: ellipsis;
			font-weight: 500;
		}

		.p-tabview-close {
			@include one-button();
			@include one-button--icon-only();
			width: $buttonClickAreaSize;
		}
	}
}

.p-tabview-panels {
	@include flexChild(auto);
	overflow-x: hidden;
	overflow-y: auto;
	width: 100%;
	height: 100%;
}

.p-tabview-panel {
	height: 100%;
}

.p-tabview-close {
	z-index: 100;

	&:hover {
		color: $negative;
	}
}

.p-tabview-scrollable {
	@include flex();

	.p-tabview-nav-container {
		width: 100%;
	}

	.p-tabview-nav-btn {
		@include one-button();
		@include one-button--icon-only();
		justify-content: center;
		position: absolute;
		top: 0;
		z-index: 3;
		background: $sectionBg;
		border-radius: 0;
		box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 20%);

		&.p-tabview-nav-prev {
			left: 0;
		}
	}
}

.p-tabview--layout {
	height: auto;

	.p-tabview-nav {
		@include flex();
		gap: $elementSpacing;
		flex-wrap: nowrap;

		li {
			max-width: min-content;
		}
	}

	.p-tabview-nav-link {
		height: $buttonClickAreaSize;
	}

	.one-tab__title {
		color: $sectionTitleText;
		white-space: nowrap;
	}

	.p-highlight .one-tab__title {
		color: $sectionTitleText;
	}

	.one-tab__icon {
		font-size: 0.7em;
	}

	.one-tab__icon--margin {
		margin-right: $elementSpacingSmall;
	}
}

.p-tabview--no-header {
	.one-tab__title {
		@include typo-h2();
		color: $sectionTitleText;
	}
}