// filter for picklist and choice values
p-multiselect.one-input {
	color: red;

	.p-multiselect-label {
		padding: 0;
	}
}

p-multiselect {

	&.one-multiselect--table-filter {
		font-size: $tableFilterFontSize;
		color: $tableHeaderText;
		background: $tableFilterBg;

		.p-multiselect-label {
			color: $placeholder;
		}
	}
}

.p-multiselect {
	@include flex();
	line-height: 2;
	width: 100%;
	height: 100%;
	border: none;

	&:focus {
		color: $text;
		border: none !important;
	}

	.p-multiselect-trigger {
		color: $inputLabel;
		font-size: .7em;
		height: 1em;
		transform: translateY(12.5%);
	}

	.p-multiselect-label {
		display: block;
		color: $inputLabel;
		overflow: hidden;
		width: auto;
		height: 2em;
		padding-left: $elementSpacingSmall;
		cursor: pointer;
		text-overflow: ellipsis;
		border: none;
	}

	&.ui-state-disabled .p-multiselect-label,
	.p-multiselect.ui-state-disabled .p-multiselect-trigger {
		cursor: auto;
	}
}

.one-multiselect--loading {
	.p-multiselect-filter-icon.pi-search {
		animation: fa-spin 1s infinite steps(8);

		&::before {
			@include fontawesome();
			content: '\f110' !important; // fa-spinner
		}
	}
}

.p-multiselect-panel {
	position: absolute;
	min-width: max-content;
	border: thin solid rgba(0, 0, 0, 0.1);
	border-radius: $borderRadius;
	background-color: $dropdownBg;
	box-shadow: $shadowAll;

	.p-multiselect-header {
		display: flex;
		flex-direction: row;
		height: $lineHeight;
		margin-left: $elementSpacingSmall;

		.p-checkbox-box .p-checkbox-icon {
			@include flexChild(none);
		}

		.p-multiselect-filter-container {
			@include flex();
			@include flexChild(auto);
			color: $dropdownText;
			width: 100%;
			margin-top: auto;
			margin-right: $elementSpacingSmall;
			margin-bottom: auto;
			margin-left: $elementSpacingSmall;
			border-radius: 0;
			background-color: transparent;

			.p-multiselect-filter {
				border-bottom: 1px solid $inputBorder;

				&:hover {
					border-bottom: 1px solid $darkGray;
				}

				&:focus {
					border-bottom: 1px solid $primary;

					+ .p-multiselect-filter-icon {
						color: $primary;
					}
				}
			}

			.p-autocomplete-multiple-container {
				@include flexChild(auto);
			}

			.p-multiselect-filter-icon {
				@include flexChild(none);
				right: 0;
				top: 0;
				margin: 0;
				color: $otherButtonText;
			}
		}

		.p-multiselect-close {
			@include flexChild(none);
			color: $otherButtonText;
			width: $lineHeight;
			height: $lineHeight;
			cursor: pointer;

			&:focus {
				color: $primary;
			}
		}
	}

	.p-multiselect-items-wrapper {
		position: relative;
		overflow: auto;
		border-top: thin solid $lightGray;
	}

	.p-multiselect-list {
		border: 0;
	}

	.p-multiselect-item,
	.p-multiselect-empty-message {
		color: $dropdownText;
		padding-right: $elementSpacingSmall;

		&:focus,
		&:hover {
			color: $primary;
		}

		> * {
			margin-left: $elementSpacingSmall;
		}

		.one-autocomplete__column-icon {
			position: relative;
		}
	}

	.p-multiselect-empty-message {
		padding: .5em;
	}
}
