@mixin th_style {
	position: relative;
	overflow: hidden;
	text-align: left;
	white-space: nowrap;
	letter-spacing: normal;
	text-transform: uppercase;
	text-overflow: ellipsis;
	border-bottom: 2px solid var(--tableBorder);
}

@mixin th_text {
	color: $tableHeaderText;
	font-size: 0.9rem;
	font-weight: 600;
	overflow: hidden;
	min-width: 0;
	text-align: left;
	white-space: nowrap;
	letter-spacing: normal;
	text-transform: uppercase;
	text-overflow: ellipsis;
}

@mixin tr_style {
	color: $tableRowText;
	font-variant-numeric: tabular-nums;
	background-color: $tableRowBg;

	&:not(.one-datatable__group--open) {

		& + tr {
			border-top: 1px solid $tableRowBorderBottom;
		}

		&:nth-child(odd) {
			--tableRowBg: #{$tableRowOdd};
		}

		&:nth-child(even) {
			--tableRowBg: #{$tableRowEven};
		}


		&:not(.one-datatable__group-paginator ) {
			&:focus,
			&:hover {
				color: $tableRowHoverText;
				--tableRowBg: #{$tableRowHoverBg};

				.one-datatable__column--sticky {
					background-color: inherit;
				}
			}
		}
	}
}