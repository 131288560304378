p-dropdown {
	display: block;
	max-width: 100%;
}

.p-dropdown {
	max-width: stretch;
}

.p-menu,
.p-tieredmenu,
.p-overlaypanel,
.p-contextmenu,
.one-field-panel,
.one-icon-overlay,
.p-dropdown-panel,
.p-password-panel,
.ql-mention-list-container,
.p-autocomplete-panel,
.p-column-filter-overlay {
	font-size: inherit;
	z-index: 999; // below z-index of dialog (will be overwritten by autoZIndex)
	overflow-x: hidden;
	overflow-y: auto;
	border: thin solid var(--dropdownBorder, rgba(0, 0, 0, 0.1));
	border-radius: $borderRadius;
	background: $dropdownBg;
	box-shadow: $shadowAll;

	.p-listbox-filter-container,
	.p-dropdown-filter-container {
		@include flex();
		width: 100%;
		height: $buttonClickAreaSize;
		padding: 0 $elementSpacingSmall;
		border: thin solid $lightGray;
		border-radius: $borderRadius;
		background: transparent;

		.p-dropdown-filter {
			@include flexChild(auto);
			color: $dropdownText;
			text-align: left;
		}
	}

	.p-listbox-filter-icon,
	.p-dropdown-filter-icon {
		@include flexChild(none);
		@include flex();
		position: initial;
		justify-content: center;
		font-size: initial;
		color: $darkGray;
		width: $buttonClickAreaSize;
		height: $buttonClickAreaSize;
		margin: 0;
	}

	&.p-dropdown-panel--one-search-bar {
		width: 100%;
		transform: translateY(calc(-1 * #{$buttonClickAreaSize}));

		.p-dropdown-filter-container {
			font-size: 1.5rem;
			border-style: none;
			border-radius: 0;
		}

		.p-dropdown-filter {
			border-bottom: thin solid $lightGray;
		}

		.p-dropdown-filter-icon {
			display: none;
		}

		.p-dropdown-item {
			flex-direction: column;
		}
	}

	.p-dropdown-items-wrapper {
		overflow: auto;
		max-height: 45vh !important;
	}

	.p-menuitem,
	.p-menuitem-parent {
		float: left;
		clear: both;
		width: 100%;
		margin: 0;
		padding: 0;
		border: 0;

		&.p-menuitem--negative {
			--dropdownText: #{$negative};
		}
	}

	.ql-mention-loading,
	.ql-mention-list-item,
	.p-listbox-item,
	.p-menuitem-link,
	.p-dropdown-item,
	.one-field-panel-item,
	.p-dropdown-item-group,
	.p-column-filter-row-item,
	.p-listbox-empty-message,
	.p-autocomplete-item-group,
	.p-autocomplete-item,
	.p-autocomplete-empty-message,
	.p-dropdown-empty-message {
		display: var(--subGridDisplay, flex);
		align-items: center;
		grid-template-columns: subgrid;
		grid-column: span var(--subGridColumns, 1);
		column-gap: $elementSpacingSmall;
		color: $dropdownText;
		width: 100%;
		padding: $elementSpacingSmall;
		cursor: pointer;
		text-decoration: none;

		&.p-disabled {
			pointer-events: none;
			cursor: not-allowed;
			color: $inputValueReadOnly;
		}

		.p-menuitem-icon {
			width: 1.25rem;
			text-align: center;
		}
	}

	.ql-mention-loading,
	.ql-mention-list-item,
	.p-listbox-item,
	.p-menuitem-link,
	.p-dropdown-item,
	.one-field-panel-item,
	.p-column-filter-row-item,
	.p-autocomplete-item {
		&:hover {
			color: $primary;
		}

		&.p-highlight,
		&.selected {
			background-color: $primary;
			color: $primaryContrast;

			* {
				color: currentColor;
			}

			.one-autocomplete__column-icon {
				color: currentColor !important;
			}
		}
	}

	.p-autocomplete-item-group,
	.p-dropdown-item-group {
		padding-top: 0.33rem;
		padding-bottom: 0.33rem;
	}

	.p-autocomplete-item-group,
	.p-autocomplete-item-group i,
	.p-dropdown-item-group,
	.p-dropdown-item-group i {
		color: $dropdownGroupBgContrast;
		cursor: default;
		background-color: $dropdownGroupBg;
	}
}

.p-autocomplete-panel {
	min-width: 100%;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.p-autocomplete-items {
	display: grid;
	grid-template-columns: repeat( var(--subGridColumns, 1), auto);
}

.ql-mention-list-container {
	z-index: 1000; // one above z-index of dialog
	width: auto;
	max-width: 33%;
}

p-dropdown {

	.p-dropdown {
		@include flex();
		color: $sectionTitleText;
		width: 100%;
	}

	.p-dropdown-label {
		@include flexChild(auto);
		line-height: normal;
	}

	.p-dropdown-trigger {
		@include flexChild(none);
	}
}

.p-contextmenu,
.p-tieredmenu {
	overflow: initial;

	.p-menuitem-active {
		& > .p-menuitem-link {
			color: $primary;
		}
	}

	.p-submenu-list {
		z-index: 10000;
		border: thin solid rgba(0, 0, 0, 0.1);
		border-radius: $borderRadius;
		background: $dropdownBg;
		box-shadow: $shadowAll;
	}
}

.p-tieredmenu {

	&.one-menu--left {
		.p-submenu-icon {
			order: -10;
			transform: rotate(180deg);
		}

		.p-menuitem-active {
			& > p-tieredmenusub > .p-submenu-list {
				left: -100%;
			}
		}
	}

	.p-submenu-icon {
		padding-left: $elementSpacingSmall;
	}
}

.p-tabmenu {

	.p-menuitem-link {
		@include flex();
		color: $text;
	}

}

/* list box (e.g. within bell overlay */

.p-listbox {
	min-width: rem(300px);
}

.p-listbox-header {
	@include flex();
	justify-content: space-between;
	margin-bottom: $elementSpacingSmall;
}

.p-listbox-list {
	max-height: max(50vh, #{rem(300px)});
}

/* overlay panel */

.one-overlay {
	padding: $condensableSpacingSmall;
}

.one-overlay__header {
	margin-bottom: $condensableSpacingSmall;
}

.one-overlay__sub-header {
	margin: $condensableSpacingSmall 0;
}

.p-overlaypanel-content {
	padding: $condensableSpacingSmall;
}

.p-overlaypanel-close {
	display: none;
}

@include phone() {
	.p-overlay--one-search-bar {
		left: $elementSpacingSmall !important;
		right: $elementSpacingSmall !important;
	}
}
