@import "base";

p-selectButton {
	max-width: 100%;
}

.p-selectbutton {
	@include flex();
	overflow: hidden;

	&.one-selectbutton--vertical {
		flex-direction: column;
	}

	&.one-selectbutton--form {
		margin-top: calc(#{$lineHeight} - 1.5rem);
		flex-wrap: wrap;
	}

	&.one-selectbutton--size-small {
		--selectButtonHeight: 1.5rem;
		--selectButtonFontSize: initial;
	}

	&.one-selectbutton--size-medium {
		--selectButtonHeight: 3rem;
		--selectButtonFontSize: 2.5rem;
	}

	&.one-selectbutton--size-large {
		--selectButtonHeight: 5rem;
		--selectButtonFontSize: 4.5rem;
	}

	&.one-selectbutton--icon-only,
	&.one-selectbutton--form {
		color: $otherButtonText;
		margin-top: calc(#{$lineHeight} - 1.5rem);
		--selectButtonBorderStyle: solid;

		&.p-disabled {
			--selectButtonBorderStyle: dashed;

			&.one-selectbutton--horizontal {
				border: thin var(--selectButtonBorderStyle) $sectionBg;
			}
		}

		&.one-selectbutton--horizontal {
			gap: 1px;
			background: $inputBorder;
			border: thin var(--selectButtonBorderStyle) $inputBorder;
			border-radius: $borderRadius;

			&.one-selectbutton--horizontal {
				border-style: var(--selectButtonBorderStyle);
			}

			.one-selectbutton__button-wrapper {
				@include flex();
				justify-content: center;
				color: $inputLabel;
				width: 100%;
				height: var(--selectButtonHeight, 1.5rem);
			}

			& > .p-button {
				background: $sectionBg;

				&.p-highlight {
					box-shadow: inset 0px -2px 0 0 var(--primary);
				}
			}
		}

		.one-selectbutton__button {
			@include flexChild(auto);
			@include flex();

			&::after {
				position: absolute;
				right: 0;
				bottom: 0;
				left: 0;
				height: 2px;
				content: '';
				background-color: transparent;
			}

			&:hover {
				.p-button {
					color: $primary;
				}
			}
		}

		.one-selectbutton__button--clear {
			@include flexChild(none);
			margin-left: rem(-10px);

			.p-button {
				width: var(--selectButtonHeight, 1.5rem);

				.p-button-icon {
					margin-right: 0;
				}
			}
		}

		.one-button {
			@include flex();
			justify-content: center;
			font-size: var(--selectButtonFontSize);
			color: $inputLabel;
			height: var(--selectButtonHeight, 1.5rem);

			&.one-button--disabled {
				color: $inputValueReadOnly !important;
			}

			.p-button-icon {
				@include flexChild(none);
				color: var(--selectButtonColor);
			}

			.p-button-label {
				@include flexChild(0 1 auto);
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				font-weight: normal;
			}
		}

		.one-button {
			&:hover {
				border-color: transparent;
			}
		}

		@include phone() {
			&.one-selectbutton--horizontal {
				& > .p-button {
					& + .p-button .one-selectbutton__button-wrapper {
						border-top-style: none;
						border-left-style: var(--selectButtonBorderStyle);
					}

					&:first-of-type .one-selectbutton__button-wrapper {
						border-top-left-radius: $borderRadius;
						border-top-right-radius: $borderRadius;
						border-bottom-right-radius: 0;
						border-bottom-left-radius: 0;
					}

					&:last-of-type .one-selectbutton__button-wrapper {
						border-top-left-radius: 0;
						border-top-right-radius: 0;
						border-bottom-right-radius: $borderRadius;
						border-bottom-left-radius: $borderRadius;
					}
				}
			}
		}
	}

	&.one-selectbutton--filter {
		.p-component {
			height: 2em;

			&.p-highlight {
				color: $green;
			}
		}
	}

	.p-component {
		@include flexChild(auto);
		@include flex();
	}
}
