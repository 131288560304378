/*** ToolTip ***/

.p-tooltip {
	position: absolute;
	z-index: 10;
	pointer-events: none;

	.p-tooltip-text {
		color: $tooltipText;
		padding: 3px 10px;
		border-radius: $borderRadius;
		background-color: $tooltipBg;
	}
}
