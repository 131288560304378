@import "base";


/* Firefox */
* {
	scrollbar-width: auto;
	scrollbar-color: $scrollBarHandleBg $scrollBarBg;
	appearance: none;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: $scrollBarWidth;
	height: $scrollBarWidth;
	appearance: none;
}

*::-webkit-scrollbar-track {
	background: $scrollBarBg;
	appearance: none;
}


*::-webkit-scrollbar-thumb {
	border: $scrollBarBorderWidth solid $scrollBarBg;
	border-radius: $scrollBarRadius;
	background-color: $scrollBarHandleBg;
	appearance: none;
}

*::-webkit-scrollbar-thumb:hover {
	background-color: $scrollBarHandleBgHover;
}
