@import "base";

.fa-rotate-90 {
	--fa-rotate-angle: 90deg;
}

.fa-rotate-180 {
	--fa-rotate-angle: 180deg;
}

.fa-rotate-270 {
	--fa-rotate-angle: 270deg;
}

.fa-rotate-360 {
	--fa-rotate-angle: 360deg;
}

.fa-rotate-45 {
	--fa-rotate-angle: 45deg;
}

.fa-rotate-135 {
	--fa-rotate-angle: 135deg;
}

.fa-rotate-225 {
	--fa-rotate-angle: 225deg;
}

.fa-rotate-315 {
	--fa-rotate-angle: 315deg;
}