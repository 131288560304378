/* radio buttons */

p-radiobutton {
	margin-right: $elementSpacing;
}

p-tableradiobutton,
p-radiobutton {
	display: inline-flex;
	align-items: center;

	--radioColor: #{$inputLabel};

	&:active,
	&:hover {
		--radioColor: #{$primary};
	}

	.p-radiobutton-checked {
		--radioColor: #{$primary};

		.p-radiobutton-icon {
			border: .4rem solid var(--radioColor);
		}
	}

	.p-radiobutton-disabled {
		--radioColor: #{$inputValueReadOnly};

		.p-radiobutton-box {
			cursor: not-allowed;
		}
	}

	.p-radiobutton-box {
		cursor: pointer;
	}

	.p-radiobutton-icon {
		position: relative;
		display: block;
		width: 1.3rem;
		height: 1.3rem;
		border: .2rem solid var(--radioColor);
		border-radius: 100vmax;
		background-color: transparent;
		transition: border 100ms ease-in-out;
	}

	.p-radiobutton-label {
		padding-left: $elementSpacingSmall;
		cursor: pointer;

		&.p-disabled {
			color: $inputValueReadOnly;
			cursor: not-allowed;
		}
	}
}
